import {
    Box,
    Button,
    Container,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Show,
    Spinner,
    useBoolean,
    useMediaQuery,
} from '@chakra-ui/react'
import { useGetPostsQuery } from '@core/app/api/postsApi'
import {PostsFilterArea, PostsFilterAreaValue, PostsFilterItems} from '@core/app/types/postsApiType'
import { Layout } from '@core/components/Layout/Layout'
import { PostsList } from '@page/posts/components/PostsList/PostsList'
import { Sidebar } from '@page/posts/components/Sidebar/Sidebar'
import { TopTab } from '@page/posts/components/TopTab/TopTab'
import { EmptyState } from '@page/posts/components/EmptyState/EmptyState'
import queryString from 'query-string'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'
import { IconSearch } from '@core/assets/icons/search'
import { Form, Formik, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { ParallaxBanner } from 'react-scroll-parallax'
import { useDebouncedCallback } from 'use-debounce'
import { useTheme } from './hooks/useTheme'

interface FormValues {
    searchValue: string
}

export const PostsPage = () => {
    const [isDesktop] = useMediaQuery('(min-width: 1230px)', {
        fallback: false,
    })
    const { bgImage, localization } = useTheme()
    const [countryIds, setCountryIds] = useState<number[]>([])
    const [regionIds, setRegionIds] = useState<number[]>([])
    const [cityIds, setCityIds] = useState<number[]>([]); // Added for cities
    const [industryIds, setIndustryIds] = useState<number[]>([])
    const [jobTypeIds, setJobTypeIds] = useState<number[]>([])
    const [officeIds, setOfficeIds] = useState<number[]>([])
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [sortValue, setSortValue] = useState<string>('newest');
    const {
        data: postsData,
        isFetching,
        isLoading,
    } = useGetPostsQuery({
        country: countryIds,
        region: regionIds,
        city: cityIds, // Added city filter
        industry: industryIds,
        jobType: jobTypeIds,
        office: officeIds,
        query: searchQuery,
        sort: sortValue,
    })
    const { data: headerInfo } = useGetHeaderInfoQuery({})
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [isSidebar, setIsSidebar] = useState<boolean>(false)
    const [isScrolled, setIsScrolled] = useState<boolean>(false)
    const [showSidebar, setShowSidebar] = useBoolean(false)
    const formRef = useRef<FormikProps<FormValues>>()

    const isLogged = headerInfo && headerInfo.statusCode === 200 && headerInfo.data?.CandidateID
    const isSignUpAvailable = headerInfo && headerInfo.statusCode === 200 && headerInfo?.menu?.signUp
    const isVacanciesExist = postsData && postsData?.data && postsData.data?.metadata?.totalCount > 0
    const isLoadingContent = !isVacanciesExist && (isFetching || isLoading)
    const heroSectionHeight = bgImage ? (isDesktop ? '280px' : '180px') : '180px'
    const headerElement = document.querySelector('header')
    const headerHeight = headerElement?.offsetHeight || 84
    const [showScrollButton, setShowScrollButton] = useState<boolean>(false);

    useEffect(() => {
        parseUrl()
    }, [])

    useEffect(() => {
        const searchString = queryString.stringify(
            {
                country: countryIds,
                region: regionIds,
                city: cityIds, // Added city filter
                branch: industryIds,
                extent: jobTypeIds,
                department: officeIds,
                query: searchQuery ? searchQuery : undefined,
            },
            { arrayFormat: 'bracket' }
        )
        localStorage.setItem('jobs_search_string', searchString)
        navigate({
            pathname: window.location.pathname,
            search: searchString,
        })
    }, [searchQuery, countryIds, regionIds, cityIds, industryIds, jobTypeIds, officeIds])

    useEffect(() => {
        if (postsData && postsData.data.filtersSettings) {
            const { area, industry, jobType, office } = postsData?.data?.filtersSettings
            if (area || industry || jobType || office) {
                setIsSidebar(true)
            }
        }
    }, [postsData])

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [isDesktop, headerHeight])

    const extractIdFromPrefixedString = (prefixedId: string): number => parseInt(prefixedId.split('-')[1], 10)

    const handleSelect = (id: number | string, type: string, value: boolean) => {
        const processedId = typeof id === 'string' ? extractIdFromPrefixedString(id) : id;
        const countryIdsForUrl: number[] = [...countryIds]
        const regionIdsForUrl: number[] = [...regionIds]
        const cityIdsForUrl: number[] = [...cityIds];
        const industryIdsForUrl: number[] = [...industryIds]
        const jobTypeIdsForUrl: number[] = [...jobTypeIds]
        const officeIdsForUrl: number[] = [...officeIds]

        if (window.scrollY > 284) {
            window.scrollTo({ top: 284, behavior: 'smooth' });
        }


        switch (type) {
            case 'country':
                const countryIdx = countryIds.indexOf(processedId);

                if (countryIdx === -1) {
                    setCountryIds((prev) => [...prev, processedId]);
                } else {
                    const newCountryIds = [...countryIds];
                    newCountryIds.splice(countryIdx, 1);
                    setCountryIds(newCountryIds);

                    const newRegionIds = regionIds.filter(regionId => {
                        return !Object.values(postsData?.data?.filters?.area[processedId].regions || {})
                            .some(region => region.id === regionId);
                    });
                    setRegionIds(newRegionIds);

                    const newCityIds = cityIds.filter(cityId => {
                        return !Object.values(postsData?.data?.filters?.area[processedId].regions || {})
                            .some(region =>
                                Object.values(region.cities || {})
                                    .some(city => (city as { id: number }).id === cityId)
                            );
                    });
                    setCityIds(newCityIds);
                }
                break;

            case 'region':
                const regionIdx = regionIds.indexOf(processedId);

                const countries = Object.values(postsData?.data?.filters?.area || {});
                countries.forEach((country: PostsFilterAreaValue) => {
                    if (country.regions[processedId]) {
                        const countryIdx = countryIds.indexOf(country.id);
                        if (value) {
                            setCountryIds((prev) => [...new Set([...prev, country.id])]);
                        } else {
                            // setCountryIds((prev) => prev.filter((i) => i !== country.id));
                        }
                    }
                })

                if (regionIdx === -1) {
                    setRegionIds((prev) => [...prev, processedId]);
                } else {
                    const newRegionIds = [...regionIds];
                    newRegionIds.splice(regionIdx, 1);
                    setRegionIds(newRegionIds);

                    const newCityIds = cityIds.filter(cityId => {
                        return !Object.values(postsData?.data?.filters?.area || {})
                            .some(area =>
                                Object.values(area.regions || {})
                                    .some(region =>
                                        (region as { id: number }).id === processedId &&
                                        Object.values((region as { cities: { id: number }[] }).cities || {})
                                            .some(city => (city as { id: number }).id === cityId)
                                    )
                            );
                    });
                    setCityIds(newCityIds);

                }
                break;

            case 'city':
                const cityIdx = cityIds.indexOf(processedId);

                if (cityIdx === -1) {
                    setCityIds((prev) => [...prev, processedId]);
                } else {
                    const arr = [...cityIds];
                    arr.splice(cityIdx, 1);
                    setCityIds(arr);
                }
                break;

            case 'industry':
                const industryIdx = industryIds.indexOf(processedId)

                if (industryIdx === -1) {
                    setIndustryIds((prev) => [...prev, processedId])
                    industryIdsForUrl.push(processedId)
                } else {
                    const arr = [...industryIds]
                    arr.splice(industryIdx, 1)
                    setIndustryIds(arr)
                    industryIdsForUrl.splice(industryIdx, 1)
                }

                break;

                case 'jobType':
                    const jobTypeIdx = jobTypeIds.indexOf(processedId)

                    if (jobTypeIdx === -1) {
                        setJobTypeIds((prev) => [...prev, processedId])
                        jobTypeIdsForUrl.push(processedId)
                    } else {
                        const arr = [...jobTypeIds]
                        arr.splice(jobTypeIdx, 1)
                        setJobTypeIds(arr)
                        jobTypeIdsForUrl.splice(jobTypeIdx, 1)
                    }
                break;

                case 'office':
                    const officeIdx = officeIds.indexOf(processedId)

                    if (officeIdx === -1) {
                        setOfficeIds((prev) => [...prev, processedId])
                        officeIdsForUrl.push(processedId)
                    } else {
                        const arr = [...officeIds]
                        arr.splice(officeIdx, 1)
                        setOfficeIds(arr)
                        officeIdsForUrl.splice(officeIdx, 1)
                    }

                    break
        }

        updateUrl();
    };

    const updateUrl = () => {
        navigate({
            pathname: window.location.pathname,
            search: queryString.stringify(
                {
                    country: countryIds,
                    region: regionIds,
                    city: cityIds,
                    branch: industryIds,
                    extent: jobTypeIds,
                    department: officeIds,
                    query: searchQuery.length > 3 ? searchQuery : null,
                },
                { arrayFormat: 'bracket' }
            ),
        });
    };

    const debouncedSearch = useDebouncedCallback((submitForm) => {
        submitForm()
        if (window.scrollY > (isDesktop ? 284 : headerHeight)) {
            window.scrollTo({ top: (isDesktop ? 284 : headerHeight), behavior: 'smooth' })
        }
    }, 900)

    const reset = () => {
        localStorage.setItem('jobs_search_string', '')
        setCountryIds([])
        setRegionIds([])
        setCityIds([]);
        setIndustryIds([])
        setJobTypeIds([])
        setOfficeIds([])
        setSearchQuery('')
        navigate({
            pathname: window.location.pathname,
            search: queryString.stringify(
                {
                    country: [],
                    region: [],
                    city: [],
                    branch: [],
                    extent: [],
                    department: [],
                },
                { arrayFormat: 'bracket' }
            ),
        })
    }

    const parseUrl = () => {
        const { query } = queryString.parseUrl(new URL(location.href).search)
        if (query.query) {
            setSearchQuery(query.query as string)
        }
        getIds(query['country[]'], 'country')
        getIds(query['region[]'], 'region')
        getIds(query['city[]'], 'city')
        getIds(query['branch[]'], 'industry')
        getIds(query['extent[]'], 'jobType')
        getIds(query['department[]'], 'office')
    }

    const getIds = (ids: any, type: string) => {
        if (typeof ids === 'object') {
            ids?.map((item: any) => {
                if (item) {
                    switch (type) {
                        case 'country':
                            const countryIdx = countryIds.indexOf(+item)
                            if (countryIdx === -1) {
                                setCountryIds((prev) => [...prev, +item])
                            }
                            break
                        case 'region':
                            const regionIdx = regionIds.indexOf(+item)
                            if (regionIdx === -1) {
                                setRegionIds((prev) => [...prev, +item])
                            }
                            break
                        case 'city':
                            const cityIdx = cityIds.indexOf(+item)
                            if (cityIdx === -1) {
                                setCityIds((prev) => [...prev, +item])
                            }
                            break
                        case 'industry':
                            const industryIdx = industryIds.indexOf(+item)
                            if (industryIdx === -1) {
                                setIndustryIds((prev) => [...prev, +item])
                            }
                            break
                        case 'jobType':
                            const jobTypeIdx = jobTypeIds.indexOf(+item)
                            if (jobTypeIdx === -1) {
                                setJobTypeIds((prev) => [...prev, +item])
                            }
                            break
                        case 'office':
                            const officeIdx = officeIds.indexOf(+item)
                            if (officeIdx === -1) {
                                setOfficeIds((prev) => [...prev, +item])
                            }
                            break
                    }
                }
            })
        } else {
            if (ids) {
                switch (type) {
                    case 'country':
                        setCountryIds([+ids])
                        break
                    case 'region':
                        setRegionIds([+ids])
                        break
                    case 'city':
                        setCityIds([+ids])
                        break
                    case 'industry':
                        setIndustryIds([+ids])
                        break
                    case 'jobType':
                        setJobTypeIds([+ids])
                        break
                    case 'office':
                        setOfficeIds([+ids])
                        break
                }
            }
        }
    }

    const handleSortChange = (value: string) => {
        setSortValue(value)
    };

    const handleScroll = () => {
        const offset = window.scrollY
        if (isDesktop) {
            setIsScrolled(offset > headerHeight + 100)
        } else {
            setIsScrolled(offset > (headerHeight + 50))
        }

        if (window.scrollY > 600) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    }

    const getPaddingTop = () => {
        if (isScrolled) {
            return heroSectionHeight
        }
    }

    const initialValues: FormValues = {
        searchValue: searchQuery ? searchQuery : '',
    }

    if (isLoadingContent) {
        return (
            <Flex w={'full'} h={'100vh'} justifyContent={'center'} alignItems={'center'}>
                <Spinner
                    size={'xl'}
                    thickness={'4px'}
                    speed={'0.65s'}
                    emptyColor={'formElements.borderDefault'}
                    color={'state.primary'}
                />
            </Flex>
        )
    }

    if (!isVacanciesExist && !isLoadingContent) {
        return (
            <Layout useContainer={false} useMobileHeader={true} showFooter={true}>
                <EmptyState isDesktop={true} isLogged={isLogged} isSignUpAvailable={isSignUpAvailable} />
            </Layout>
        )
    }

    return (
        <Layout useContainer={false} useMobileHeader={true} fixedHeader={true} showFooter={(isDesktop && isVacanciesExist) || !isDesktop}>
            <>
                <Box
                    position={isScrolled ? 'fixed' : 'relative'}
                    top={isScrolled ? '84px' : '84px'}
                    left={isScrolled ? '0' : 'auto'}
                    right={isScrolled ? '0' : 'auto'}
                    height={isScrolled ? '0' : heroSectionHeight}
                    zIndex="10"
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                        transition="height 0.5s ease"
                        height={isScrolled ? '80px' : '100%'}
                    >
                        {bgImage && (
                            <ParallaxBanner
                                layers={[{ image: bgImage, speed: -30 }]}
                                className="aspect-[2/1]"
                                style={{
                                    height: '100%',
                                    backgroundSize: 'cover',
                                    position: 'absolute',
                                }}
                            />
                        )}
                        <Box
                            h="full"
                            bg="rgba(0,0,0,0.4)"
                            position="absolute"
                            left={0}
                            right={0}
                            top={0}
                            bottom={0}
                        />

                        <Formik
                            enableReinitialize={true}
                            // @ts-ignore
                            innerRef={formRef}
                            initialValues={initialValues}
                            onSubmit={(values) => {
                                setSearchQuery(values.searchValue)
                            }}
                        >
                            {({ handleSubmit, handleChange, values, submitForm }) => {
                                const handleSearchChange = (e: { target: { value: any } }) => {
                                    handleChange(e)
                                    debouncedSearch(submitForm)
                                }

                                return (
                                    <Container maxW={{ base: 'container.sm', xl: 'container.xl' }}>
                                        <Form
                                            onSubmit={handleSubmit}
                                            style={{
                                                width: '100%',
                                                maxWidth: '468px',
                                                margin: '0 auto'
                                            }}
                                        >
                                            <InputGroup
                                                w={'100%'}
                                                maxW={{ base: '343px', xl: 'full' }}
                                                borderRadius={'8px'}
                                                overflow={'hidden'}
                                                mx={{ base: 'auto', xl: '0' }}
                                            >
                                                <InputLeftElement pointerEvents={'none'}>
                                                    <IconSearch w={'16'} h={'16'} fill={'#333333'} />
                                                </InputLeftElement>
                                                {localization[10] ? (
                                                    <Input
                                                        placeholder={localization[10]}
                                                        name={'searchValue'}
                                                        value={values.searchValue}
                                                        onChange={handleSearchChange}
                                                        autoComplete={'off'}
                                                        borderRadius={'8px'}
                                                    />
                                                ) : (
                                                    <Input
                                                        placeholder={`${t('2493')}`}
                                                        name={'searchValue'}
                                                        value={values.searchValue}
                                                        onChange={handleSearchChange}
                                                        autoComplete={'off'}
                                                        borderRadius={'8px'}
                                                    />
                                                )}
                                            </InputGroup>
                                        </Form>
                                    </Container>
                                )
                            }}
                        </Formik>
                    </Box>
                </Box>
                <Box height="84px" position={'relative'}>
                </Box>

                <Show below={'xl'}>
                    <TopTab
                        data={postsData?.data?.job_posts}
                        settings={postsData?.data?.filtersSettings}
                        searchQuery={(value) => setSearchQuery(value)}
                        open={() => setShowSidebar.toggle()}
                        onReset={() => reset()}
                    />
                </Show>
                <Container
                    maxW={{ base: 'full', xl: 'container.xl' }}
                    mx={{ base: 0, xl: 'auto' }}
                    px={{ base: 0, xl: 'auto' }}
                    pt={getPaddingTop()}
                    pb={'30px'}
                    className={'posts-page-main-container'}
                >
                    <Box as={'section'} py={{ base: 4, xl: 8 }}>
                        <Flex justifyContent={isSidebar ? 'space-between' : 'center'} alignItems={'flex-start'}>
                            {(isDesktop && isSidebar) || (!isDesktop && isSidebar) ? (
                                <Sidebar
                                    meta={postsData?.data?.metadata}
                                    filters={postsData?.data?.filters}
                                    settings={postsData?.data?.filtersSettings}
                                    onSelect={handleSelect}
                                    reset={() => reset()}
                                    isOpened={showSidebar}
                                    closeModal={() => setShowSidebar.off()}
                                />
                            ) : null}
                            <PostsList
                                data={postsData?.data?.job_posts}
                                settings={postsData?.data?.filtersSettings}
                                reset={() => reset()}
                                onSortChange={handleSortChange}
                            />
                        </Flex>
                    </Box>
                    {showScrollButton && (
                        <Button
                            className={'back-to-top-button'}
                            position="sticky"
                            bottom={isDesktop ? '30px' : '90px'}
                            left="30px"
                            display={'flex'}
                            gap={'10px'}
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        >
                            <i className="fa-regular fa-arrow-up"></i>
                            {
                                isDesktop &&
                                <span>
                                    {t('8010')}
                                </span>
                            }
                        </Button>
                    )}
                </Container>

            </>
        </Layout>
    )
}
