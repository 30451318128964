import { logworkApi } from "@core/app/api/logworkApi";
import { RootState } from '@core/app/store/store';
import { CalendarAvailability, CalendarHoliday, CalendarShift, CalendarTrip, Job, LoggedWorkItem, SummaryDetail } from "@core/app/types/logworkApiType";
import { createSlice } from "@reduxjs/toolkit";

interface LogworkState {
    asideWidth: number
    jobs: Job[]
    loggedWork: {
        [key: string]: LoggedWorkItem[]
    } | null,
    choosedLoggedWork: {
        [key: string]: LoggedWorkItem[]
    } | null,
    shifts: CalendarShift[]
    availabilities: CalendarAvailability[]
    holidays: CalendarHoliday[]
    trips: CalendarTrip[]
    selectedJobs: {
        manualHours: {
            [dayIndex: number]: Job | null;
        },
        piecework: {
            [dayIndex: number]: Job | null;
        },
        hours: {
            [dayIndex: number]: Job | null;
        },
        addition: {
            [dayIndex: number]: Job | null;
        },
    },
    loggedWorkChildProjects: {
        childProjectId: number,
        name: string
    }[]
    childProjects: {
        [key: string]: {
            childProjectId: number,
            name: string
        }[]
    }
    resetLogTable: boolean
    isSidebarOpened: boolean
    isLogWorkHoursErrors: {
        [key: string]: {
            [key: string]: string[]
        }
    } | null
    summaryStats: {
        breakQty: number
        grossQty: number
        netQty: number
        qty: number
        salary: number
        invoice: number
        details: SummaryDetail[]
    } | null
}

const initialState: LogworkState = {
    asideWidth: 0,
    jobs: [],
    loggedWork: null,
    choosedLoggedWork: null,
    shifts: [],
    availabilities: [],
    holidays: [],
    trips: [],
    selectedJobs: {
        manualHours: {},
        piecework: {},
        hours: {},
        addition: {}
    },
    resetLogTable: false,
    loggedWorkChildProjects: [],
    isSidebarOpened: true,
    isLogWorkHoursErrors: null,
    childProjects: {},
    summaryStats: null
}

const logworkSlice = createSlice({
    name: 'logwork',
    initialState,
    reducers: {
        setJobs(state, action) {
            state.jobs = action.payload
        },
        setLoggedWork(state, action) {
            state.loggedWork = action.payload
        },
        setSelectedJobs(state, action) {
            state.selectedJobs = action.payload
            if (action.payload) {
                state.childProjects = action.payload.childProjects
            }
        },
        setChoosedLoggedWork(state, action) {
            state.choosedLoggedWork = action.payload
        },
        setSelectedJob(state, action) {
            state.selectedJobs = action.payload
        },
        setResetLogTable(state, action) {
            state.resetLogTable = action.payload
        },
        setIsSidebarOpened(state, action) {
            state.isSidebarOpened = action.payload
        },
        handleLogWorkErrors(state, action) {
            state.isLogWorkHoursErrors = action.payload
        },
        setAsideWidth(state, action) {
            state.asideWidth = action.payload
        },
    },
    extraReducers(builder) {
        builder.addMatcher(logworkApi.endpoints.getJobs.matchFulfilled, (state, action) => {
            state.jobs = action.payload;

            const primaryJob = action.payload.find((job: Job) => job.isPrimary);
            const jobsForDays: Record<number, Job | null> = {};

            if (primaryJob) {
                for (let i = 0; i < 7; i++) {
                    jobsForDays[i] = primaryJob;
                }
            } else if (action.payload.length === 1) {
                const singleJob = action.payload[0];
                for (let i = 0; i < 7; i++) {
                    jobsForDays[i] = singleJob;
                }
            } else {
                for (let i = 0; i < 7; i++) {
                    jobsForDays[i] = null;
                }
            }

            state.selectedJobs = {
                manualHours: { ...jobsForDays },
                piecework: { ...jobsForDays },
                hours: { ...jobsForDays },
                addition: { ...jobsForDays },
            };

            action.payload.forEach((job: Job) => {
                if (job.childProjects.length > 0) {
                    Object.assign(state.childProjects, {
                        [job.jobId]: [...job.childProjects]
                    });
                }
            });
        })
        builder.addMatcher(logworkApi.endpoints.getLoggedHours.matchFulfilled, (state, action) => {
            state.loggedWork = action.payload.loggedWork
            state.summaryStats = action.payload.summary
            state.loggedWorkChildProjects = action.payload.childProjects
        }),
        builder.addMatcher(logworkApi.endpoints.getCalendarVariables.matchFulfilled, (state, action) => {
            if (action.payload.shifts) {
                const logged0 = action.payload.shifts.filter((shift: CalendarShift) => shift.logged === 0)
                state.shifts = logged0
            }
            if (action.payload.availability) {
                state.availabilities = action.payload.availability
            }
            if (action.payload.nationalHolidays) {
                state.holidays = action.payload.nationalHolidays
            }
            if (action.payload.trips) {
                state.trips = action.payload.trips
            }
        })
    }
})

export default logworkSlice.reducer;
export const { setJobs, setLoggedWork, setSelectedJobs, setSelectedJob, setResetLogTable, setIsSidebarOpened, handleLogWorkErrors, setAsideWidth, setChoosedLoggedWork } = logworkSlice.actions;
export const storeJobs = (state: RootState) => state.logwork.jobs;
export const storeLoggedWork = (state: RootState) => state.logwork.loggedWork;
export const storeSelectedJobs = (state: RootState) => state.logwork.selectedJobs;
export const storeResetLogTable = (state: RootState) => state.logwork.resetLogTable;
export const storeLoggedWorkChildProjects = (state: RootState) => state.logwork.loggedWorkChildProjects;
export const storeShifts = (state: RootState) => state.logwork.shifts;
export const storeIsSidebarOpened = (state: RootState) => state.logwork.isSidebarOpened;
export const storeIsLogWorkHoursErrors = (state: RootState) => state.logwork.isLogWorkHoursErrors;
export const storeChildProjects = (state: RootState) => state.logwork.childProjects;
export const storeSummaryStats = (state: RootState) => state.logwork.summaryStats;
export const storeAvailabilities = (state: RootState) => state.logwork.availabilities;
export const storeHolidays = (state: RootState) => state.logwork.holidays;
export const storeTrips = (state: RootState) => state.logwork.trips;
export const storeAsideWidth = (state: RootState) => state.logwork.asideWidth;
export const storeChoosedLoggedWork = (state: RootState) => state.logwork.choosedLoggedWork;
