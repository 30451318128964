import { Box, Button, Flex, HStack, Image, ModalCloseButton, Text } from '@chakra-ui/react'
import { LocaleItem } from '@core/app/types/globalApiType'
import { CustomModal } from '@core/components/Modal/Modal'
import { useEffect, useState } from 'react'

import homeIcon from '@core/assets/images/mobileheader_home.svg'
import postsIcon from '@core/assets/images/mobileheader_posts.svg'
import contactsIcon from '@core/assets/images/mobileheader_contacts.svg'
import newsIcon from '@core/assets/images/newspaper-solid.svg'
import { AppButton } from '@core/components/AppButton'
import { useTranslation } from 'react-i18next'
import { Locale } from '@core/app/types/profileApiType'

interface Props {
    locale: Locale
    opened: boolean
    news: boolean | undefined
    contacts: boolean | undefined
    onClose: () => void
    openLang: () => void
}

export const UnauthorizedHeader = ({ opened, news, contacts, onClose, openLang, locale }: Props) => {
    const [isOpened, setIsOpened] = useState(false)

    const { t } = useTranslation()

    const isEnglishLang = (value: string) => {
        const str = value
        const parts = str.split('(')
        return parts[0] + ' (' + parts[1]
    }

    useEffect(() => {
        setIsOpened(opened)
    }, [opened])

    return (
        <CustomModal
            toBottom={true}
            isOpen={isOpened}
            isHeaderVisible={false}
            isFooterVisible={false}
            showOverlay={false}
            showFrom={'slideInBottom'}
            onClose={() => onClose()}
            body={
                <Box bg={'bg.1'} borderRadius={'8px 8px 0 0'}>
                    <Flex flexDirection={'column'} w={'full'}>
                        <Flex w={'full'} px={4} py={5} borderBottom={'1px solid'} borderColor={'border.1'}>
                            {locale &&
                                locale.current &&
                                Object.values(locale.current).map((flag: LocaleItem) => (
                                    <HStack key={flag.id} cursor={'pointer'} onClick={() => openLang()}>
                                        <Image
                                            maxW={5}
                                            src={
                                                process.env.ENV === 'local'
                                                    ? `${process.env.API_URL}/${flag.flag}`
                                                    : flag.flag
                                            }
                                            alt={flag.name}
                                        />
                                        <Text>
                                            {flag.name.includes('English(US)')
                                                ? isEnglishLang(flag.name)
                                                : `${flag.name}`}
                                        </Text>
                                    </HStack>
                                ))}
                            <ModalCloseButton top={4} />
                        </Flex>
                        <Flex
                            w={'full'}
                            px={4}
                            py={5}
                            borderBottom={'1px solid'}
                            borderColor={'border.1'}
                            gap={3}
                            onClick={() => (location.href = '/')}
                        >
                            <Image src={homeIcon} />
                            <Text fontWeight={'medium'} color={'text.secondary'}>
                                {t('201')}
                            </Text>
                        </Flex>
                        <Flex
                            w={'full'}
                            px={4}
                            py={5}
                            borderBottom={'1px solid'}
                            borderColor={'border.1'}
                            gap={3}
                            onClick={() => (location.href = '/jobs')}
                        >
                            <Image src={postsIcon} />
                            <Text fontWeight={'medium'} color={'text.secondary'}>
                                {t('2495')}
                            </Text>
                        </Flex>
                        {news && (
                            <Flex
                                w={'full'}
                                px={4}
                                py={5}
                                borderBottom={'1px solid'}
                                borderColor={'border.1'}
                                gap={3}
                                onClick={() => (location.href = '/newsfeed')}
                            >
                                <Image src={newsIcon} w="20px" />
                                <Text fontWeight={'medium'} color={'text.secondary'}>
                                    {t('5137')}
                                </Text>
                            </Flex>
                        )}
                        {contacts && (
                            <Flex
                                w={'full'}
                                px={4}
                                py={5}
                                borderBottom={'1px solid'}
                                borderColor={'border.1'}
                                gap={3}
                                onClick={() => (location.href = '/contacts')}
                            >
                                <Image src={contactsIcon} w="20px" />
                                <Text fontWeight={'medium'} color={'text.secondary'}>
                                    {t('2762')}
                                </Text>
                            </Flex>
                        )}
                        <Flex w={'full'} px={4} py={5} borderBottom={'1px solid'} borderColor={'border.1'} gap={'9px'}>
                            <Button
                                variant={'secondary'}
                                w={'calc(50% - 9px)'}
                                onClick={() => {
                                    location.href = '/login'
                                }}
                            >
                                {t('1890')}
                            </Button>
                            <AppButton
                                variant={'primary'}
                                w={'calc(50% - 9px)'}
                                onClick={() => (location.href = '/sign-up')}
                                title={t('2496')}
                            />
                        </Flex>
                    </Flex>
                </Box>
            }
        />
    )
}
