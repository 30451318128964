import { GetPolicy, GetTheme, Locale, LocaleItem, GetNews, NewsDetailResponse } from '@core/app/types/globalApiType';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const globalApi = createApi({
    reducerPath: 'globalApi',
    baseQuery: fetchBaseQuery({ 
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)
    
            return headers
        },
    

        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin'
    }),
    tagTypes: ['Locale'],
    endpoints: (builder) => ({
        getLocale: builder.query<Locale, void>({
            query: () => ({
                url: '/locale',
                method: 'GET',
            }),
            providesTags: ['Locale']
        }),
        changeLocale: builder.mutation<{statusCode: number, data: {current: LocaleItem}}, {data: {languageId: number}}>({
            query: (body) => ({
                url: '/locale/change',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Locale']
        }),
        getTheme: builder.query<GetTheme, { preview_token?: string }>({
            query: ({ preview_token }) => {
                return {
                    url: `/theme`,
                    method: 'GET',
                    params: { preview_token }
                }
            },
         }),
        getPolicy: builder.query<GetPolicy, { type: string }>({
            query: ({ type }) => ({
                url: `/policy`,
                method: 'GET',
                params: { type }
            })
        }),
        getNews: builder.query<GetNews, { limit: number, page: number }>({
            query: ({ limit, page }) => `/newsfeed?limit=${limit}&page=${page}`,
        }),
        
        getNewsDetail: builder.query<NewsDetailResponse, string | number>({
            query: (newsId) => `/newsfeed/${newsId}`,
        }),
    }),
})

export const { useGetLocaleQuery, useChangeLocaleMutation, useGetThemeQuery, useGetPolicyQuery, useGetNewsQuery, useGetNewsDetailQuery } = globalApi