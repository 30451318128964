import { Textarea } from '@chakra-ui/react'
import { ChangeEvent, forwardRef } from 'react'
import ResizeTextarea from 'react-textarea-autosize'

interface Props {
    size?: string
    bg?: string
    placeholder?: string
    name?: string
    fontSize?: string
    value?: string
    onChange?: (e: ChangeEvent) => void
    maxLength?: number
    minRows?: number
}

export const AutoResizeTextarea = forwardRef(({ minRows = 5, ...props }: Props, ref) => {
    return (
        <Textarea
            borderRadius={'4px'}
            px={2}
            minH={'unset'}
            overflow={'hidden'}
            w={'100%'}
            resize={'none'}
            // @ts-ignore
            ref={ref}
            minRows={minRows}
            onFocus={(e) => console.log('1')}
            onBlur={(e) => console.log('2')}
            as={ResizeTextarea}
            {...props}
        />
    )
})
