import {
    Box,
    Flex,
    HStack,
    Image,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useGetJobApplicationsQuery } from '@core/app/api/postsApi'
import { Localization } from '@core/app/types/globalApiType'
import { PostsItem } from '@core/app/types/postsApiType'
import { IconJobType } from '@core/assets/icons/job_type'
import appliedIcon from '@core/assets/images/applied_icon.svg'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { getDateWithShortMonthName } from '@core/utils/helpers/getDateWithShortMonthName'
import locationIcon from '@page/company/assets/images/location.svg'
import timeIcon from '@page/company/assets/images/time.svg'
import incompleteIcon from '@page/posts/assets/images/incomplete.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

interface Props {
    postData: PostsItem[0] | undefined
}

export const Post = ({ postData }: Props): JSX.Element => {
    const { data: applications } = useGetJobApplicationsQuery({})
    const { t } = useTranslation()
    const { appTheme } = useAppTheme()
    const [localization, setLocalization] = useState<Localization>({})
    const [applied, setApplied] = useState<boolean>(false)

    const mainThemeJobPostTextColor = appTheme ? `#${appTheme.newSettings.general.JobPostText}` : undefined
    const currentPost = applications?.data?.find((item) => item.AdID === postData?.AdID)
     const formattedDate = postData ? getDateWithShortMonthName(postData.Expire, t) : ''

    const getDifference = (expireDate: string) => {
        const date1 = new Date(expireDate)
        let date2 = new Date()
        // @ts-ignore
        date2 = date2.setHours(0, 0, 0, 0)
        // @ts-ignore
        const diffInMs = Math.abs(date2 - date1)

        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

        return diffInDays
    }

    const isApplied = () => {
        if (applications && applications.data && applications.data.length > 0 && postData) {
            if (currentPost?.Active === 1) {
                return (
                    <Flex
                        py={'6px'}
                        pl={'6px'}
                        pr={'12px'}
                        borderRadius={'24px'}
                        bg={'state.success'}
                        gap={2}
                        alignItems={'center'}
                        cursor={'pointer'}
                    >
                        <Image src={appliedIcon} maxW={'16px'} alt={'applied icon'} />
                        <Text fontSize={'xs'} color={'text.white'} fontWeight={'semibold'}>
                            {t('1215')}
                        </Text>
                    </Flex>
                )
            }
            if (currentPost?.Active === 2) {
                return (
                    <Popover trigger={'hover'}>
                        <PopoverTrigger>
                            <Flex
                                py={'6px'}
                                pl={'6px'}
                                pr={'12px'}
                                borderRadius={'24px'}
                                bg={'state.error1'}
                                gap={2}
                                alignItems={'center'}
                                cursor={'pointer'}
                            >
                                <Image src={incompleteIcon} maxW={'16px'} alt={'incomplete icon'} />
                                <Text fontSize={'xs'} color={'text.white'} fontWeight={'semibold'}>
                                    {t('3226')}
                                </Text>
                            </Flex>
                        </PopoverTrigger>
                        <PopoverContent
                            border={'1px solid #E0E0E0'}
                            boxShadow={'0px 16px 40px 0px rgba(0, 0, 0, 0.08)'}
                        >
                            <PopoverArrow />
                            <PopoverBody>
                                <Text fontSize={'xs'} color={'text.main'} fontWeight={'medium'}>
                                    {t('7101')}
                                </Text>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                )
            }

            return
        }
    }

    // Determine border color based on the currentPost status
    let borderColor = 'transparent'
    if (currentPost?.Active === 1) {
        borderColor = 'state.success'
    } else if (currentPost?.Active === 2) {
        borderColor = 'state.warning'
    }

    // Calculate the difference in days if postData is defined
    const daysDifference = postData ? +getDifference(postData.Expire) : 0

    // Determine the message to display based on daysDifference
    const expirationMessage = !applied && postData ? (
        daysDifference < 12 && daysDifference !== 0 && daysDifference !== 1 ? (
            `${t('3141')} ${daysDifference} ${t('1039')}`
        ) : daysDifference === 1 ? (
            t('153')
        ) : daysDifference === 0 ? (
            t('152')
        ) : null
    ) : null

    useEffect(() => {
        if (applications && applications.data && applications.data.length > 0 && postData) {
            const post = applications.data.find((item) => item.AdID === postData.AdID)
            if (post) {
                setApplied(true)
            }
        }
    }, [applications, postData])

    useEffect(() => {
        if (!appTheme) return
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    return (
        <Box
            as={'div'}
            bg={'bg.1'}
            borderRadius={'8px'}
            overflow={'hidden'}
            p={6}
            w={'full'}
            boxShadow={'0px 1px 6px 0px rgba(0,0,0,0.15)'}
            border={'2px solid'}
            borderColor={borderColor}
            cursor={'pointer'}
            transition={'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'}
            _hover={{
                shadow: 'md',
                boxShadow: '0px 1px 6px 0px rgba(0,0,0,0.35)',
                transform: 'scale(1.02)',
                transitionDuration: '0.2s',
                // transitionTimingFunction: "ease-in-out"
            }}
            onClick={() => {
                if(postData) location.href = `${window.location.origin}/job/${postData?.AdID}`
            }}
        >
            {postData ? (
                <Flex gap={6} flexDirection={{ base: 'column', xl: 'row' }}>
                    <Flex minW={'200px'} justifyContent={'center'} alignItems={'center'} cursor={'pointer'}>
                        <LazyLoadImage
                            src={postData.AdLogo}
                            effect={'blur'}
                            style={{
                                maxWidth: '200px',
                                maxHeight: '136px',
                                objectFit: 'contain'
                            }}
                            onClick={() => (location.href = `${window.location.origin}/job/${postData.AdID}`)}
                            alt={postData.Name}
                        />
                    </Flex>
                    <VStack
                        gap={1}
                        alignItems={'start'}
                        justifyContent={'start'}
                        w={'full'}
                        minH={{ base: '100%', xl: '150px' }}
                    >
                        <Flex w={'full'} gap={4} alignItems={'start'} justifyContent={'flex-end'}>
                            <Link
                                fontSize={'xl'}
                                fontWeight={'semibold'}
                                color={mainThemeJobPostTextColor ? mainThemeJobPostTextColor : 'text.link'}
                                _hover={{ textDecoration: 'none' }}
                                href={`${window.location.origin}/job/${postData.AdID}`}
                                mr={'auto'}
                                maxW={{ base: '275px', xl: '400px' }}
                            >
                                {postData.Name}
                            </Link>
                            {isApplied()}
                        </Flex>
                        <HStack gap={1} flexWrap={'wrap'}>
                            <Text
                                fontSize={'sm'}
                                color={'text.thirdly'}
                                textTransform={'capitalize'}
                                display={'inline'}
                            >
                                {postData.EmpName}
                            </Text>
                            {postData.Positions > 1 && (
                                <Text
                                    fontSize={'sm'}
                                    color={'text.thirdly'}
                                    textTransform={'capitalize'}
                                    display={'inline'}
                                >
                                    ·{' '}
                                    {localization[11]
                                        ? `${postData.Positions} ${localization[11]}`
                                        : postData.Positions === 1
                                          ? `${postData.Positions} ${t('5369')}`
                                          : `${postData.Positions} ${t('2413')}`}
                                </Text>
                            )}
                        </HStack>
                        <Text fontSize={'sm'} fontWeight={'medium'} color={'text.secondary'}>
                            {postData.JobTitle}
                        </Text>
                        <Flex
                            flexDirection={{ base: 'column', xl: 'row' }}
                            justifyContent={'space-between'}
                            w={'full'}
                            gap={{ base: 1, xl: 0 }}
                            mt={{ base: '0', xl: 'auto' }}
                        >
                            <Flex flexDirection={{ base: 'column', xl: 'row' }} w={'full'} gap={{ base: 1, xl: 0 }}>
                                {postData.Place && (
                                    <Flex gap={1} mr={3} maxW={'200px'} alignItems={'center'}>
                                        <Image w={3} h={3} src={locationIcon} alt={'location icon'} />
                                        <Text fontSize={'sm'} color={'text.secondary'}>
                                            {postData.Place}
                                        </Text>
                                    </Flex>
                                )}

                                <Flex gap={1} alignItems={'center'}>
                                    <IconJobType />
                                    <Text fontSize={'sm'} color={'text.secondary'}>
                                        {postData.Extent}
                                    </Text>
                                </Flex>

                                <Flex gap={1} ml={{ base: '0', xl: 'auto' }} alignItems={'center'}>
                                    <Image w={3} h={3} src={timeIcon} alt={'timer icon'} />
                                    <Flex>
                                        <Text fontSize={'sm'} color={'text.thirdly'}>
                                            {t('6632')}:
                                        </Text>
                                        {expirationMessage && (
                                            <Text
                                                ml={1}
                                                fontSize={'sm'}
                                                fontWeight={'medium'}
                                                color={'state.warning'}
                                            >
                                                {expirationMessage}
                                            </Text>
                                        )}
                                        <Text
                                            ml={1}
                                            fontSize={'sm'}
                                            color={expirationMessage ? 'text.thirdly' : 'text.main'}
                                            fontWeight={expirationMessage ? 'normal' : 'semibold'}
                                        >
                                            {expirationMessage ? `(${formattedDate})` : formattedDate}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </VStack>
                </Flex>
            ) : null}
        </Box>
    )
}
