import {
    Avatar,
    Box,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
} from '@chakra-ui/react'
import { useLogoutMutation } from '@core/app/api/authApi'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'
import { setResetForm } from '@core/app/slices/profile'
import { useAppDispatch } from '@core/utils/hooks/hook'
import { useLocalStorage } from 'usehooks-ts'

export const EmployeeHeader = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { data, isSuccess } = useGetHeaderInfoQuery({})
    const [logout] = useLogoutMutation()
    const [value, setValue] = useLocalStorage('isSidebarOpened', true)

    const handleToggle = () => {
        setValue(!value)
    }

    const handleLogout = async () => {
        try {
            const response = await logout().unwrap()
            localStorage.removeItem('mobile_verification')

            location.href = response.redirectUrl
            localStorage.removeItem('apply_dialog')
            localStorage.removeItem('service_code')
            localStorage.removeItem('is_logged')
            localStorage.removeItem('after_apply')
            localStorage.removeItem('applying_process')
            localStorage.removeItem('after_apply_id')
            localStorage.removeItem('after_apply_title')
            dispatch(setResetForm(true))
        } catch (error) {}
    }

    const menuItems = [
        {
            link: '/profile',
            type: 'link',
            iconClassName: 'fa-light fa-user fa-lg',
            label: 'Profile',
        },
        {
            link: '/profile/settings',
            type: 'link',
            iconClassName: 'fa-light fa-gear fa-lg',
            label: 'Settings',
        },
        {
            type: 'divider',
        },
        {
            link: undefined,
            action: handleLogout,
            label: 'Logout',
            iconClassName: 'fa-light fa-arrow-right-from-bracket fa-lg',
        },
    ]

    return (
        <Box
            w={'full'}
            h={'64px'}
            bg={'bg.2'}
            px={'30px'}
            py={'14px'}
            borderBottom={'1px solid'}
            borderColor={'border.3'}
            pos={'sticky'}
            top={0}
            left={'0'}
            zIndex={1002}
        >
            <Flex alignItems={'center'} w={'full'} justifyContent={'space-between'}>
                <IconButton
                    color={'icons.primary'}
                    w={'20px'}
                    minW={'20px'}
                    background={'none'}
                    _hover={{
                        background: 'none',
                        color: 'icons.main',
                    }}
                    icon={<i className="fa-light fa-bars fa-xl"></i>}
                    aria-label={'collapse sidebar'}
                    onClick={handleToggle}
                />
                <Menu closeOnSelect>
                    {({ isOpen }) => (
                        <>
                            <MenuButton
                                as={IconButton}
                                aria-label={'Header menu'}
                                variant={'ghost'}
                                bg={'none'}
                                _hover={{ bg: 'none' }}
                                _active={{ bg: 'none' }}
                            >
                                <Flex alignItems={'center'} gap={'8px'}>
                                    {isSuccess && (
                                        <>
                                            <Avatar src={data?.data.ProfilePicture} />
                                            <i className={`fa-light  ${isOpen ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                                        </>
                                    )}
                                </Flex>
                            </MenuButton>
                            <MenuList maxW={'200px'}>
                                {menuItems.map((item, index) =>
                                    item.type === 'divider' ? (
                                        <MenuDivider key={index} />
                                    ) : (
                                        <MenuItem
                                            key={index}
                                            as={'a'}
                                            href={item.link}
                                            icon={<i className={item.iconClassName}></i>}
                                            _hover={{ bg: '#F5F5F5' }}
                                            onClick={item.action}
                                            _active={{ bg: '#F5F5F5' }}
                                            bg={{ bg: '#F5F5F5' }}
                                        >
                                            <Text fontWeight={'medium'} fontSize={'sm'} color={'#0C0C0D'}>
                                                {item.label}
                                            </Text>
                                        </MenuItem>
                                    )
                                )}
                            </MenuList>
                        </>
                    )}
                </Menu>
            </Flex>
        </Box>
    )
}
