import { Container, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import noVacanciesImageMobile from '../../../../core/assets/images/no-vacancies-mobile.png';
import noVacanciesImage from '../../../../core/assets/images/no-vacancies.png';
import { AppButton } from '@core/components/AppButton';

interface EmptyStateProps {
    isDesktop: boolean;
    isLogged: number | false | undefined
    isSignUpAvailable: boolean | undefined
}

export const EmptyState = ({ isDesktop, isLogged, isSignUpAvailable }: EmptyStateProps) => {
    const { t } = useTranslation();

    return (
        <Container maxW={{ base: 'full', xl: 'container.xl' }} position="relative">
            <Flex justifyContent="center">
                <Flex
                    w={{ base: '100%', xl: '800px' }}
                    justifyContent={{ base: 'center', xl: 'space-between' }}
                    gap="80px"
                >
                    <Image
                        src={isDesktop ? noVacanciesImage : noVacanciesImageMobile}
                        maxH={{ base: '700px', xl: '100%' }}
                    />
                    <Flex
                        flexDirection="column"
                        w={{ base: '343px', xl: '384px' }}
                        alignSelf="center"
                        alignItems={{ base: 'center', xl: 'start' }}
                        position={{ base: 'absolute', xl: 'static' }}
                        top={{ base: '50%', xl: 'auto' }}
                        left={{ base: '50%', xl: 'auto' }}
                        transform={{ base: 'translate(-50%, -50%)', xl: 'none' }}
                        marginTop={{ base: 0, xl: isLogged ? '-195px' : '-100px' }}
                    >
                        {isLogged ? (
                            <>
                                <Text fontSize="2xl" fontWeight="semibold" lineHeight="32px" color="#529CFF">
                                    {t('6928')},{' '}
                                    <Text color="#2F80ED" display="inline">
                                        {t('6929')}!
                                    </Text>
                                </Text>
                                <Text fontSize="sm" color="text.thirdly" fontWeight="medium" mt={4}>
                                    {t('6930')}!
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text fontSize="2xl" fontWeight="semibold" lineHeight="32px" color="#529CFF">
                                    {t('6931')}{' '}
                                    {isSignUpAvailable ? (
                                        <Text color="#2F80ED" display="inline">
                                            {t('6932')}
                                        </Text>
                                    ) : null}
                                </Text>
                                {isSignUpAvailable ? (
                                    <Text fontSize="sm" color="text.thirdly" fontWeight="medium" mt={4}>
                                        {t('6933')}
                                    </Text>
                                ) : null}

                                {isSignUpAvailable ? (
                                    <AppButton
                                        title={t('2496')}
                                        variant="primary"
                                        w="184px"
                                        mt={8}
                                        onClick={() => (location.href = '/sign-up')}
                                    />
                                ) : null}
                            </>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Container>
    );
};
