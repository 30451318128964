import { useEffect, useState } from 'react'
import { useGetThemeQuery } from '@core/app/api/globalApi'
import { Localization } from '@core/app/types/globalApiType'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'

export const useTheme = () => {
    const { appTheme, updateTheme, isExpired } = useAppTheme()
    const { data: theme } = useGetThemeQuery({}, { skip: isExpired })
    const [bgImage, setBgImage] = useState<string>('')
    const [localization, setLocalization] = useState<Localization>({})

    useEffect(() => {
        if (!appTheme) return
        if (appTheme && appTheme.localization) {
            setLocalization(appTheme.localization)
        }

        const newSettings = appTheme.newSettings.general
        if (newSettings && newSettings.BackgroundUrl) {
            setBgImage(newSettings.BackgroundUrl)
        } else {
            setBgImage('')
        }
        
    }, [appTheme])

    useEffect(() => {
        if (theme) {
            updateTheme(theme)
        }
    }, [theme])

    return { bgImage, localization }
}
