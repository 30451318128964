import { ApplyDialog } from '@core/components/ApplyWindow/ApplyWindow'
import dayjs from 'dayjs'
import React, { createContext, useContext, useEffect, useState } from 'react'

interface IsApplyDialogContextType {
    isApplyDialog: boolean
    resized: boolean
    open: (id: string, job: Job) => void
    close: () => void
    collapseDialog: () => void
    error: boolean
    expiredDate: string
}

type Job = {
    link: string
    title: string
    expiredDate: string
}

const IsApplyDialogContext = createContext<IsApplyDialogContextType>({
    isApplyDialog: localStorage.getItem('apply_dialog') === 'true' ? true : false,
    open: () => {},
    close: () => {},
    resized: localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).resized,
    collapseDialog: () => {},
    error: false,
    expiredDate:
        localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).job?.expiredDate,
})

export const IsApplyDialogProvider = ({ children }: { children: React.ReactNode }) => {
    const expiredDate =
        localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).job?.expiredDate
    const [isApplyDialog, setIsApplyDialog] = useState(
        localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).opened
    )
    const [resized, setResized] = useState(
        localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).resized
    )
    const [error, setError] = useState<boolean>(false)
    const oldId = localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).id

    const close = () => {
        setIsApplyDialog(false)
        const newObject = {
            opened: false,
        }
        localStorage.setItem('apply_dialog', JSON.stringify(newObject))
    }

    const open = (id: string, job: Job) => {
        if (oldId && oldId === id) {
            setError(true)

            setTimeout(() => {
                setError(false)
            }, 3000)
        } else {
            setIsApplyDialog(true)
            let newObject = {
                opened: true,
                id: id,
                job: job,
                resized: false,
                expiredDate: job.expiredDate,
            }
            setResized(false)
            localStorage.setItem('apply_dialog', JSON.stringify(newObject))
            document.querySelector('.p-dialog-content')?.classList.remove('p-dialog-hidden')
            document.querySelector('.p-dialog')?.classList.remove('p-dialog-content-hidden')
        }
    }

    const changeLocalStorageData = () => {
        const object = localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!)
        let newObject = {
            opened: true,
            id: object.id,
            job: object.job,
            expiredDate: object.job.expiredDate,
            resized: false,
        }
        if (object && object.resized) {
            newObject.resized = false
            setResized(false)
        } else {
            newObject.resized = true
            setResized(true)
        }

        localStorage.setItem('apply_dialog', JSON.stringify(newObject))
    }

    const collapseDialog = () => {
        changeLocalStorageData()
        if (resized) {
            document.querySelector('.p-dialog-content')?.classList.add('p-dialog-hidden')
            document.querySelector('.p-dialog')?.classList.add('p-dialog-content-hidden')
        } else {
            document.querySelector('.p-dialog-content')?.classList.remove('p-dialog-hidden')
            document.querySelector('.p-dialog')?.classList.remove('p-dialog-content-hidden')
        }
    }

    useEffect(() => {
        if (dayjs(expiredDate).diff(dayjs(expiredDate), 'day') < 0) {
            close()
        }
    }, [])

    return (
        <IsApplyDialogContext.Provider
            value={{ isApplyDialog, open, close, resized, collapseDialog, error, expiredDate }}
        >
            {<ApplyDialog isActive={isApplyDialog} onHide={close} />}
            {children}
        </IsApplyDialogContext.Provider>
    )
}

export const useIsApplyDialog = () => {
    return useContext(IsApplyDialogContext)
}
