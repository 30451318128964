import { Box, Text } from '@chakra-ui/react'
import { useGetAttributesForApplyQuery } from '@core/app/api/profileApi'
import { ApplyJobSettings } from '@core/app/types/postsApiType'
import { Attr } from '@core/app/types/profileApiType'
import { ApplicationText } from '@page/post/components/ApplyJob/ApplicationText/ApplicationText'
import { AddAttribute } from '@page/profile/components/Sections/Attributes/AddAttribute'
import { useTranslation } from 'react-i18next'

interface Props {
    id: string
    settings: ApplyJobSettings | undefined
    isSettingsLoading: boolean
    isSettingsFetching: boolean
}

export const Attributes = ({ id, settings, isSettingsLoading, isSettingsFetching }: Props) => {
    const { data: attributes, isSuccess } = useGetAttributesForApplyQuery({
        id,
    })
    const { t } = useTranslation()

    const hasNewAttributes =
        isSuccess && attributes && attributes.data && Object.values(attributes.data.newAttributes || {}).length > 0

    return (
        <Box mt={{ base: '0', xl: 6 }}>
            {hasNewAttributes && settings?.askAttributes ? (
                <Box>
                    <Text fontSize={'sm'} fontWeight={'medium'} color={'text.secondary'} mb={5}>
                        {t('5874')}
                    </Text>
                    {Object.values(attributes?.data?.newAttributes || {}).map((attr: Attr) => (
                        <AddAttribute
                            key={attr.CandidateAttributeID}
                            Name={attr.Name}
                            IsBool={attr.IsBool}
                            AvailableValues={attr.AvailableValues}
                            CandidateAttributeID={attr.CandidateAttributeID}
                            IsCheckbox={attr.IsCheckbox}
                            IsCheckboxRating={attr.IsCheckboxRating}
                            IsDropdown={attr.IsDropdown}
                            IsRating={attr.IsRating}
                            isText={attr.IsText}
                            isTextInput={attr.IsTextInput}
                            isDate={attr.IsDate}
                            jobId={id}
                        />
                    ))}
                </Box>
            ) : (
                <ApplicationText
                    settings={settings}
                    isSettingsLoading={isSettingsLoading}
                    isSettingsFetching={isSettingsFetching}
                />
            )}
        </Box>
    )
}
